import React, { useEffect } from "react";
import CursorFollower from "../components/cursorFollower";
import { motion } from "framer-motion";
import VanillaTilt from "vanilla-tilt";
import { HashLink } from "react-router-hash-link";
import ReactGA from "react-ga";
import { CDN } from "../config";

function Interactions() {
  useEffect(() => {
    const isLaptop = window.matchMedia("(min-width: 1024px)").matches;

    if (isLaptop) {
      const element = document.querySelector(".my-image");
      VanillaTilt.init(element);
      // element.addEventListener("tiltChange", callback);

      const cards = document.querySelectorAll(".int-card");

      cards.forEach((card) => {
        card.addEventListener("mouseover", () => {
          cards.forEach((c) => {
            if (c !== card) c.classList.add("dim");
          });
        });

        card.addEventListener("mouseout", () => {
          cards.forEach((c) => c.classList.remove("dim"));
        });
      });
    }
  });

  return (
    <div>
      {" "}
      <CursorFollower />
      <motion.div
        animate={{ opacity: 1, translateY: "0px" }}
        initial={{ opacity: 0, translateY: "50px" }}
        transition={{ duration: 1.5 }}
        exit={{ opacity: 0, translateY: "50px" }}
        className="course-projects-container"
      >
        {/* <h1>Course Projects</h1> */}

        <div className="course-project-tiles">
          <div className="int-card">
            <div className="int-card-left">
              <i>Action:</i>
              <h3>Onboarding Flow</h3>
              <br />
              <i>Project:</i>
              <h3>GardenTag - Course Project</h3>
            </div>
            <div className="interactionIphone">
              <img
                className="iphone"
                src={`${CDN}/interactions/iPhone15.png`}
                alt=""
              />
              <p style={{ position: "absolute" }}>loading...</p>
              <img
                className="iphone-gif"
                loading="lazy"
                src={`${CDN}/interactions/gardentag1.gif`}
                alt=""
              />
            </div>
          </div>

          <div className="int-card">
            <div className="int-card-left">
              <i>Action:</i>
              <h3>Pairing GardenTag</h3>
              <br />
              <i>Project:</i>
              <h3>GardenTag - Course Project</h3>
            </div>
            <div className="interactionIphone">
              <img
                className="iphone"
                src={`${CDN}/interactions/iPhone15.png`}
                alt=""
              />
              <p style={{ position: "absolute" }}>loading...</p>
              <img
                className="iphone-gif"
                loading="lazy"
                src={`${CDN}/interactions/gardentag2.gif`}
                alt=""
              />
            </div>
          </div>

          <div className="int-card">
            <div className="int-card-left">
              <i>Action:</i>
              <h3>Bike Reserve Flow</h3>
              <br />
              <i>Project:</i>
              <h3>Beemove - Course Project</h3>
            </div>
            <div className="interactionIphone">
              <img
                className="iphone"
                src={`${CDN}/interactions/iPhone15.png`}
                alt=""
              />
              <p style={{ position: "absolute" }}>loading...</p>
              <img
                className="iphone-gif"
                loading="lazy"
                src={`${CDN}/interactions/Bike_Reserving.gif`}
                alt=""
              />
            </div>
          </div>

          <div className="int-card">
            <div className="int-card-left">
              <i>Action:</i>
              <h3>Place a sofa in the living room</h3>
              <br />
              <i>Project:</i>
              <h3>Virtual Staging - Course Project</h3>
            </div>
            <div className="interactionIphone">
              <img
                className="iphone"
                src={`${CDN}/interactions/iPhone15.png`}
                alt=""
              />
              <p style={{ position: "absolute" }}>loading...</p>
              <img
                className="iphone-gif"
                loading="lazy"
                src={`${CDN}/interactions/sofa.gif`}
                alt=""
              />
            </div>
          </div>

          <div className="int-card">
            <div className="int-card-left">
              <i>Action:</i>
              <h3>Measure the size of the house</h3>
              <br />
              <i>Project:</i>
              <h3>Virtual Staging - Course Project</h3>
            </div>
            <div className="interactionIphone">
              <img
                className="iphone"
                src={`${CDN}/interactions/iPhone15.png`}
                alt=""
              />
              <p style={{ position: "absolute" }}>loading...</p>
              <img
                className="iphone-gif"
                loading="lazy"
                src={`${CDN}/interactions/task 2.gif`}
                alt=""
              />
            </div>
          </div>

          <div className="int-card">
            <div className="int-card-left">
              <i>Action:</i>
              <h3>Virtualize house staging with a single click using AI</h3>
              <br />
              <i>Project:</i>
              <h3>Virtual Staging - Course Project</h3>
            </div>
            <div className="interactionIphone">
              <img
                className="iphone"
                src={`${CDN}/interactions/iPhone15.png`}
                alt=""
              />
              <p style={{ position: "absolute" }}>loading...</p>
              <img
                className="iphone-gif"
                loading="lazy"
                src={`${CDN}/interactions/task 4.gif`}
                alt=""
              />
            </div>
          </div>

          <div className="int-card">
            <div className="int-card-left">
              <i>Action:</i>
              <h3>Contact realtor</h3>
              <br />
              <i>Project:</i>
              <h3>Virtual Staging - Course Project</h3>
            </div>
            <div className="interactionIphone">
              <img
                className="iphone"
                src={`${CDN}/interactions/iPhone15.png`}
                alt=""
              />
              <p style={{ position: "absolute" }}>loading...</p>
              <img
                className="iphone-gif"
                loading="lazy"
                src={`${CDN}/interactions/task 5.gif`}
                alt=""
              />
            </div>
          </div>

          <div className="int-card">
            <div className="int-card-left">
              <i>Action:</i>
              <h3>Switch between doll house view to floor plan</h3>
              <br />
              <i>Project:</i>
              <h3>Virtual Staging - Course Project</h3>
            </div>
            <div className="interactionIphone">
              <img
                className="iphone"
                src={`${CDN}/interactions/iPhone15.png`}
                alt=""
              />
              <p style={{ position: "absolute" }}>loading...</p>
              <img
                className="iphone-gif"
                loading="lazy"
                src={`${CDN}/interactions/task1.gif`}
                alt=""
              />
            </div>
          </div>

          <div className="int-card">
            <div className="int-card-left">
              <i>Action:</i>
              <h3>Create New Notes</h3>
              <br />
              <i>Project:</i>
              <h3>Pet Project</h3>
            </div>
            <div className="interactionIphone">
              <img
                className="normal-gif"
                loading="lazy"
                src={`${CDN}/interactions/notes_new2.gif`}
                alt=""
              />
            </div>
          </div>

          <div className="int-card">
            <div className="int-card-left">
              <i>Action:</i>
              <h3>Choose Beer Flavor</h3>
              <br />
              <i>Project:</i>
              <h3>Pet Project</h3>
            </div>
            <div className="interactionIphone">
              <img
                className="normal-gif"
                loading="lazy"
                src={`${CDN}/interactions/can.gif`}
                alt=""
              />
            </div>
          </div>

          <div className="int-card">
            <div className="interactionIphone">
              <img
                loading="lazy"
                className="bunker_interaction"
                src={`${CDN}/interactions/Secret_Bunker.gif`}
                alt=""
              />
            </div>
          </div>

          <div className="int-card">
            <div className="int-card-left">
              <i>Action:</i>
              <h3>Contact me</h3>
              <br />
              <i>Project:</i>
              <h3>My Portfolio</h3>
            </div>
            <div className="interactionIphone">
              <img
                className="normal-gif"
                loading="lazy"
                src={`${CDN}/interactions/Contactme.gif`}
                alt=""
              />
            </div>
          </div>

          <p>More interactions will be uploaded soon...</p>
        </div>
      </motion.div>
    </div>
  );
}

export default Interactions;
